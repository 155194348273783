import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import { ProtectedAdminRoute, ProtectedRoute } from "./hooks/PrivateRoute";
import { Game } from "./pages/Game/game";
import { Loader } from "./components/loader/loader";
import MenuAppBar from "./components/menuAppBar/Menuappbar";
import { MainLayout } from "./layouts/main.layout";
import { GameLayout } from "./layouts/game.layout";
import Rules, { accordionData } from "./pages/Rules/Rules";

import {
  ProfileDeposit,
  ProfileWithdrawal,
  ProfilePaymentHistory,
} from "./components/Profile/Payments/";
import Login from "./pages/Login/Login";
import Registration from "./pages/Registration/Registration";
import AuthRoute from "./hooks/authRoute";
import {
  ProfileContacts,
  ProfilePersonalInfo,
  ProfileVerification,
} from "./components/Profile/PersonalInfo";
import ProfileSecurity from "./components/Profile/ProfileSecurity/ProfileSecurity";
// import { Analytics } from "@vercel/analytics/react";
// import { SpeedInsights } from "@vercel/speed-insights/react";
import ProfileActiveBonuses from "./components/Profile/ProfileActiveBonuses/ActiveBonus/ProfileActiveBonuses";
import ProfileAvailableBonuses from "./components/Profile/ProfileActiveBonuses/AvailableBonus/ProfileAvailableBonuses";
import ProfileHistoryBonuses from "./components/Profile/ProfileActiveBonuses/HistoryBonus/HistoryBonus";
import { useAppDispatch } from "./store/hooks/store.hooks";
import { fetchBonuses } from "./store/models/bonuses/bonuses.thunk";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider, createTheme } from "@mui/material";
import { NotFound } from "./components/404/NotFound";
import { YandexConfirmation } from "./pages/Login/yandex/YandexConfirmation";
import { SteamConfirmation } from "./pages/Login/steam/SteamConfirmation";

const RequestPassword = React.lazy(
  () => import("./pages/Resetpassword/RequestPassword")
);
const Partner = React.lazy(() => import("./pages/Partner/partner"));
const Club = React.lazy(() => import("./pages/Club/Club"));
const Coins = React.lazy(() => import("./pages/Coins/Coins"));

const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const Lobby = React.lazy(() => import("./pages/Lobby/Lobby"));
const Bonuses = React.lazy(() => import("./pages/Bonuses/Bonuses"));
const BonusId = React.lazy(() => import("./pages/Bonuses/BonusID/bonus.id"));
const ProfileLayout = React.lazy(() => import("./layouts/profile.layout"));
// const Payments = React.lazy(() => import("./pages/Payments/Payments"));
const PaymentsSeccessful = React.lazy(
  () => import("./pages/Payments/PaymentsSeccessful/PaymentsSeccessful")
);
const PaymentsFailure = React.lazy(
  () => import("./pages/Payments/PaymentsFailure/PaymentsFailure")
);
const Main = React.lazy(() => import("./pages/Main/main"));

// const RequestPassword = "./pages/Resetpassword/RequestPassword";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#df0043",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#131a2f",
      paper: "#1b2740",
    },
    text: {
      primary: "#f8f8f8",
      secondary: "#5e5e72",
      disabled: "#5e5e72",
    },
  },
  typography: {
    fontSize: 12,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
  },
  shape: {
    borderRadius: 14,
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          border: "none",
        },
      },
    },
  },
  spacing: [2, 4, 8, 12, 16, 25, 32, 36],
});

const App = () => {
  // const { bonuses } = useAppSelector(bonusesListSelector);
  const dispatch = useAppDispatch();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [hidden, setHidden] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  useEffect(() => {
    async function fetch() {
      setGlobalLoading(true);
      await dispatch(fetchBonuses({ mobile: isMobile }));
      setGlobalLoading(false);
    }
    fetch();
  }, [dispatch, isMobile]);

  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <MenuAppBar hidden={hidden} />
        <React.Suspense fallback={<Loader />}>
          {!globalLoading ? (
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Main />} />
                <Route
                  path="profile"
                  //element={<ProfileLayout />
                  element={
                    <ProtectedRoute component={ProfileLayout} redirect />
                  }
                >
                  <Route index element={<Profile />} />
                  <Route path="personal-info">
                    <Route index element={<ProfilePersonalInfo />} />
                    <Route path="contacts" element={<ProfileContacts />} />
                    <Route
                      path="verification"
                      element={<ProfileVerification />}
                    />
                  </Route>
                  <Route path="payments">
                    <Route index element={<ProfileDeposit />} />
                    <Route path="withdrawal" element={<ProfileWithdrawal />} />
                    <Route
                      path="payment-history"
                      element={<ProfilePaymentHistory />}
                    />
                  </Route>

                  <Route path="bonuses">
                    <Route index element={<ProfileActiveBonuses />} />
                    <Route
                      path="available"
                      element={<ProfileAvailableBonuses />}
                    />
                    <Route path="history" element={<ProfileHistoryBonuses />} />
                  </Route>
                  <Route path="security">
                    <Route index element={<ProfileSecurity />} />
                  </Route>
                </Route>
                <Route
                  path="partner"
                  element={<ProtectedRoute component={Partner} />}
                ></Route>
                <Route
                  path="coins"
                  element={<ProtectedAdminRoute component={Coins} />}
                />
                <Route
                  path="club"
                  element={<ProtectedAdminRoute component={Club} />}
                />
                <Route path="payments">
                  {/* <Route
                    path="successful"
                    element={<ProtectedRoute component={PaymentsSeccessful} />}
                  /> */}
                  <Route
                    path="failure"
                    element={<ProtectedRoute component={PaymentsFailure} />}
                  />
                </Route>
                {accordionData.map((el) => (
                  <Route key={el.id} path={el.pathname} element={<Rules />} />
                ))}
              </Route>
              <Route path="login" element={<AuthRoute component={Login} />} />
              <Route
                path="login/yandex/callback"
                element={<YandexConfirmation />}
              />
              <Route
                path="login/steam/callback"
                element={<SteamConfirmation />}
              />
              <Route
                path="registration"
                element={<AuthRoute component={Registration} />}
              />
              <Route path="/slots/:game" element={<GameLayout />}>
                <Route index element={<Game setHidden={setHidden} />} />
              </Route>

              <Route
                path="/deposit"
                element={<div style={{ color: "#fff" }}>deposit</div>}
              />

              <Route path="/lobby" element={<Lobby />} />
              <Route path="/bonuses">
                <Route index element={<Bonuses />} />
                <Route path=":bonusId" element={<BonusId />} />
              </Route>
              <Route
                path="/authorization/restore/:token"
                element={<AuthRoute component={RequestPassword} />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <Loader />
          )}
        </React.Suspense>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
