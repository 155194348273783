import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthService } from "../../../services/auth/auth.service";
import { IStore } from "../../typing/interface";
import { setUser } from "../user/user.slice";
import {
  setAuth,
  setAuthorizationModal,
  setRegistrationModal,
  setVerifyed,
} from "./auth.slice";
import {
  IRegistrationResponse,
  IResetPasswordResponse,
} from "../../../services/auth/typing/auth.interface";
import { AuthAction } from "./typing/auth.enum";
import { isMyError } from "../../../utils/catchError";
import { TLoginGoogleResponse } from "../../../services/auth/typing/auth.type";

export const login = createAsyncThunk<
  { error: string },
  { email: string; password: string; token: string; promo_code?: string },
  { state: IStore }
>(AuthAction.LOGIN, async (_, { dispatch }) => {
  const { email, password, token, promo_code } = _;

  const authService = new AuthService();

  const { result, error } = await authService.login(
    email,
    password,
    token,
    promo_code
  );

  if (error && error.response.status === 400) {
    return error.response.data;
  }

  if (result) {
    const {
      accessToken,
      id,
      contacts,
      wallets,
      verification,
      nickname,
      personalInfo,
      currencies,
      verificationStage,
      coins,
      loyalty,
    } = result;
    localStorage.setItem("game-token", accessToken);
    dispatch(setAuth(true));
    dispatch(
      setUser({
        id,
        contacts,
        currencies,
        verification,
        nickname,
        personalInfo,
        verificationStage,
        wallets,
        coins,
        loyalty,
      })
    );
  }
  dispatch(setVerifyed(true));
});

export const loginWithGoogle = createAsyncThunk<
  { error: string },
  { googleResponse: TLoginGoogleResponse; promocode: string },
  { state: IStore }
>(AuthAction.LOGIN_WITH_GOOGLE, async (_, { dispatch }) => {
  const { googleResponse, promocode } = _;

  const authService = new AuthService();

  //@ts-ignore
  const { result, error } = await authService.loginWithGoogle(
    googleResponse,
    promocode
  );
  if (error && error.response.status === 400) {
    return error.response.data;
  }

  if (result) {
    const {
      accessToken,
      id,
      contacts,
      wallets,
      verification,
      nickname,
      personalInfo,
      currencies,
      verificationStage,
      coins,
      loyalty,
    } = result;
    localStorage.setItem("game-token", accessToken);
    dispatch(setAuth(true));
    dispatch(setAuthorizationModal(false));
    dispatch(setRegistrationModal(false));
    dispatch(
      setUser({
        id,
        contacts,
        currencies,
        verification,
        nickname,
        personalInfo,
        verificationStage,
        wallets,
        coins,
        loyalty,
      })
    );
    localStorage.removeItem("regkey");
  }
});
export const loginWithYandex = createAsyncThunk(
  AuthAction.LOGIN_WITH_YANDEX,
  async (_, { dispatch }) => {
    const authService = new AuthService();

    //@ts-ignore
    await authService.loginWithYandex();
  }
);

export const registration = createAsyncThunk<
  { data: IRegistrationResponse },
  { email: string; password: string; promocod: string; token: string },
  { state: IStore }
>(
  AuthAction.REGISTRATION,
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { email, password, promocod, token } = _;

    try {
      const authService = new AuthService();
      const { result, error } = await authService.registration(
        email,
        password,
        promocod,
        token
      );
      if (error && error.response.status === 400) {
        return error.response.data;
      }

      if (result) {
        localStorage.setItem("game-token", result.accessToken);
        dispatch(setAuth(true));
        dispatch(
          setUser({
            id: result.id,
            contacts: result.contacts,
            // currency: result.currency,
            // balance: result.balance,
            currencies: result.currencies,
            wallets: result.wallets,
            verification: result.verification,
            verificationStage: result.verificationStage,
            nickname: result.nickname,
            personalInfo: result.personalInfo,
            coins: result.coins,
            loyalty: result.loyalty,
          })
        );
        localStorage.removeItem("regkey");
      }
    } catch (error) {
      if (isMyError(error)) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue({
          message: `Things exploded (${(error as Error).message})`,
        });
      }
    }
    dispatch(setVerifyed(true));
  }
);
export const logout = createAsyncThunk<null, undefined, { state: IStore }>(
  AuthAction.LOGOUT,
  async (_, { getState, dispatch }) => {
    // dispatch(setAuthorizationModal(false));
    // dispatch(setRegistrationModal(false));
    try {
      const authService = new AuthService();
      // window.location.href = "/";
      await authService.logout();
      // if (result) {
      localStorage.removeItem("game-token");

      dispatch(
        setUser({
          id: "",
          contacts: {
            email: "",
            emailConfirmation: false,
            phone: "",
            phoneConfirmation: false,
          },
          nickname: "",
          verificationStage: "expectation",
          verification: "false",
          personalInfo: {
            name: "",
            surname: "",
            secondname: "",
            date: "",
            sex: "",
          },
          currencies: [],
          wallets: [
            {
              active: false,
              balance: {
                id: 0,
                main_balance: "",
                bonus_balance: "",
                reserved_balance: "",
              },
              currency: {
                id: 0,
                name: "RUB",
                default: true,
              },
              id: 0,
            },
          ],
          coins: 0,
          loyalty: {
            points: 0,
            level: 0,
          },
        })
      );
      dispatch(setAuth(false));

      // }
    } catch (error) {
      // console.log(error);
    } finally {
      dispatch(setVerifyed(false));
    }
    return null;
  }
);

export const verifyAuthUser = createAsyncThunk<
  void,
  undefined,
  { state: IStore }
>(AuthAction.VERIFY_AUTHORIZATION, async (_, { dispatch }) => {
  const token = localStorage.getItem("game-token");
  if (token) {
    try {
      const authService = new AuthService();

      const { result } = await authService.verifyUser();
      if (result) {
        localStorage.setItem("game-token", result.accessToken);
        dispatch(setAuth(true));
        dispatch(
          setUser({
            id: result.id,
            contacts: result.contacts,
            // currency: result.currency,
            currencies: result.currencies,
            verificationStage: result.verificationStage,
            wallets: result.wallets,
            verification: result.verification,
            nickname: result.nickname,
            personalInfo: result.personalInfo,
            coins: result.coins,
            loyalty: result.loyalty,
          })
        );
      }
    } catch (error) {
      // console.log(error);
    } finally {
    }
  }
  dispatch(setVerifyed(true));
});

export const requestResetPassword = createAsyncThunk<
  { error: IResetPasswordResponse },
  { email: string },
  { state: IStore }
>(AuthAction.RESET_LINK, async (_, { getState, dispatch, rejectWithValue }) => {
  const { email } = _;
  try {
    const authService = new AuthService();

    const { result, error } = await authService.resetLink(email);
    if (error && error.response.status === 403) {
      return error.response.data;
    }
    if (result) {
    }
  } catch (error) {}
});
export const resetPassword = createAsyncThunk<
  { error: IResetPasswordResponse },
  { password: string; token: string },
  { state: IStore }
>("auth/resetPassword", async (_, { getState, dispatch }) => {
  const { password, token } = _;

  try {
    const authService = new AuthService();

    const { result, error } = await authService.resetPassword(password, token);

    if (error && error.response.status === 401) {
      return error.response.data;
    }
    if (error && error.response.status === 402) {
      //Cannot set password to old one
      return error.response.data;
    }
    if (result) {
      // localStorage.setItem("game-token", token);
      // debugger;
      // dispatch(setAuthorizationModal(true));
      //message: "Successfully changed"
    }
  } catch (error) {}
});
export const validatePassword = createAsyncThunk<
  { error: IResetPasswordResponse },
  { password: string },
  { state: IStore }
>("auth/validatePassword", async (_, { getState, dispatch }) => {
  const { password } = _;

  try {
    const authService = new AuthService();

    const { result, error } = await authService.validatePassword(password);
    // debugger;
    if (error && error.response.status === 401) {
      return error.response.data;
    }
    if (error && error.response.status === 402) {
      //Cannot set password to old one
      return error.response.data;
    }
    if (result) {
      // localStorage.setItem("game-token", token);
      // debugger;
      // dispatch(setAuthorizationModal(true));
      //message: "Successfully changed"
    }
  } catch (error) {}
});

export const activateEmail = createAsyncThunk<
  { error: string },
  { param: string },
  { state: IStore }
>(AuthAction.ACTIVATION_EMAIL, async (_, { dispatch }) => {
  const { param } = _;
  try {
    const authService = new AuthService();

    const { result, error } = await authService.activationEmail(param);

    if (error && error.response.status === 400) {
      return error.response.data;
    }
    if (result) {
      dispatch(verifyAuthUser());
      return result;
    }
  } catch (error) {}
});
