import { createSlice } from "@reduxjs/toolkit";
import reducers from "./loyalty.reducers";
import { ILoyanltyState } from "./typing/loyalty.interface";

const initialState: ILoyanltyState = {
  levels: null,
  max_level: null,
};

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers,
});

export const { setBrandLoyalty } = loyaltySlice.actions;

export default loyaltySlice.reducer;
