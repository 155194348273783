// import { API_URL } from "../fetch";
import { RequestService } from "../../store/models/request/request.service";
import { IBonusesService } from "./typing/bonuses.interface";
import { IBonus } from "../../store/models/bonuses/typing/bonuses.interface";
import { API_URL } from "../..";
import { IResponse } from "../../store/models/request/typing/request.interface";
import { ILoyaltyLevel } from "../../store/models/loyalty/typing/loyalty.interface";

export class BonusesService implements IBonusesService {
  public fetchBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus`);

    return await request.run<IBonus[]>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchActiveBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/mybonuses`);

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchAvailableBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/available`, {});

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public activateBonus = async (promocode: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/${promocode}`);

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchBonusImage = async (image_name: string) => {
    const response = await fetch(
      `${API_URL}/api/bonus/image?image_name=${image_name}`
    );
    const blob = await response.blob();
    return blob;
  };

  public fetchBonusByID = async (bonus_id: number) => {
    const request = new RequestService();

    const url = request.prepareUrl(
      `${API_URL}/api/bonus/get-bonus-by-id/?bonus_id=${bonus_id}`
    );

    return await request.run<{ bonus: IBonus }>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };
  public cancelBonus = async (bonus_id: number) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/cancel`);

    return await request.run<{ valid: boolean; message: string }>(url, {
      method: "POST",
      data: {
        bonus_id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public getLoyalty = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/loyalty`);

    return await request.run<{ levels: ILoyaltyLevel[]; max_level: number }>(
      url,
      {
        method: "GET",
      }
    );
  };

  public updateLoyalty = async (user_id: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/payments/loyalty-update`);

    return await request.run<any>(url, {
      method: "POST",
      data: {
        user_id,
      },
    });
  };
}
