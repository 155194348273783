export const userChatName = (
  user_id?: string,
  loyaltyLevel: number = 0
): string | undefined => {
  if (!user_id && loyaltyLevel === 0) {
    return undefined;
  }

  if (user_id && loyaltyLevel === 0) {
    return `${user_id}`;
  }

  if (user_id && loyaltyLevel > 0) {
    return `${user_id}: ${loyaltyLevel}`;
  }

  return undefined;
};
