import { PayloadAction } from "@reduxjs/toolkit";
import { LoyanltyAction } from "./typing/loyalty.enum";
import { ILoyaltyLevel, ILoyanltyState } from "./typing/loyalty.interface";
// import { SlideAction } from "./typing/loyalty.enum";
// import { ISliderSlide } from "./typing/slide.interface";

const reducers = {
  [LoyanltyAction.SET_BRAND_LOYALTY]: (
    state: ILoyanltyState,
    action: PayloadAction<{ levels: ILoyaltyLevel[]; max_level: number }>
  ): void => {
    const { levels, max_level } = action.payload;

    state.levels = levels;
    state.max_level = max_level;
  },
};

export default reducers;
