import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import "./bonus.dialog.css";

type IBonusDialog = {
  infoMessage: {
    show: boolean;
    message: string;
  };
  loading: boolean;
  infoMessageButton: string;
  closeDialog: () => void;
  dialogHandler: () => void;
};

export const BonusDialog = ({
  infoMessage,
  loading,
  infoMessageButton,
  closeDialog,
  dialogHandler,
}: IBonusDialog) => {
  return (
    <Dialog
      open={infoMessage.show}
      onClose={closeDialog}
      className="game-dialog bonus-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {infoMessage.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          className={"Button Button-secondary"}
          onClick={dialogHandler}
          variant="contained"
          size="small"
          sx={{ mt: 3, mb: 2 }}
        >
          {infoMessageButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
