import { combineReducers, Reducer, ReducersMapObject } from "redux";
import authSlice from "./models/auth/auth.slice";
import userSlice from "./models/user/user.slice";
import slotsSlice from "./models/slots/slot.slice";
import paymentSlice from "./models/payment/payment.slice";
import bonusesSlice from "./models/bonuses/bonuses.slice";
import profileSlice from "./models/profile/profile.slice";
import sliderSlice from "./models/slider/slider.slice";
import loyaltySlice from "./models/loyalty/loyalty.slice";

export function rootReducer<T>(): Reducer<T> {
  const reducerMap: ReducersMapObject<T> = {
    user: userSlice,
    profile: profileSlice,
    authorization: authSlice,
    slots: slotsSlice,
    payments: paymentSlice,
    bonuses: bonusesSlice,
    slider: sliderSlice,
    loyalty: loyaltySlice,
  } as unknown as ReducersMapObject<T>;
  // @ts-ignore
  return combineReducers({
    ...reducerMap,
  });
}
