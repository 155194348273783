import { PayloadAction } from "@reduxjs/toolkit";
import { UserAction } from "./typing/user.enum";
import { IUser, IVerivicationStage } from "./typing/user.interface";

const reducers = {
  [UserAction.SET_USER]: (state: IUser, action: PayloadAction<IUser>): void => {
    const {
      id,
      contacts: { email, emailConfirmation, phone, phoneConfirmation },
      verificationStage,
      verification,
      nickname,
      personalInfo,
      wallets,
      currencies,
      coins,
      loyalty,
    } = action.payload;
    // analytics.identify(id, { email, phone, ...personalInfo });
    state.currencies = currencies;
    state.id = id;
    state.wallets = wallets;
    state.contacts = { email, emailConfirmation, phone, phoneConfirmation };
    state.verificationStage = verificationStage;
    state.verification = verification;
    state.nickname = nickname;
    state.personalInfo = personalInfo;
    state.coins = coins;
    state.loyalty = loyalty;
  },

  [UserAction.CHANGE_WALLET]: (state: IUser, action: PayloadAction<number>) => {
    const currencyId = action.payload;

    let wallet = state.wallets.find((w) => w.currency.id === currencyId);
    const currency = state.currencies.find((c) => c.id === currencyId);

    if (!currency) {
      return;
    }
    if (!wallet) {
      wallet = {
        id: Date.now(),
        active: true,
        balance: {
          bonus_balance: "0.00000",
          id: 0,
          main_balance: "0.00000",
          reserved_balance: "0.00000",
        },
        currency: currency,
      };
      state.wallets.push(wallet);
    }

    state.wallets.forEach((w) => {
      w.active = w.currency.id === currencyId;
    });
  },
  [UserAction.GET_USER]: (state: IUser) => {},
  [UserAction.UPDATE_VERIFICATION]: (
    state: IUser,
    action: PayloadAction<{ state: string; stage: IVerivicationStage }>
  ) => {
    state.verificationStage = action.payload.stage;
    state.verification = action.payload.state;
  },
};

export default reducers;
