import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface IActiveBonusImageProps {
  id: number;
  type: string;
  bonus_user_fs_type: {
    bonus_amount: string | null;
    status: string;
    wagered: string;
    wager: string;
  } | null;
  bonus_user_pers_type: {
    bonus_amount: string | null;
    status: string;
    wagered: string;
    wager: string;
  } | null;
  bg_image: string | null;
  sub_image: string | null;
  display_name: string;
  description: string;
}

export const ActiveBonusImage = ({
  id,
  type,
  bonus_user_fs_type,
  bonus_user_pers_type,
  bg_image,
  sub_image,
  display_name,
  description,
}: IActiveBonusImageProps) => {
  const navagate = useNavigate();
  return (
    <Box className="my_activated_bonus__image__container">
      <Box className="my_activated_bonus__bonus-type">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <circle cx="7" cy="7" r="7" fill="white" fillOpacity="0.7" />
          <rect
            x="2.09961"
            y="2.09998"
            width="9.8"
            height="9.8"
            fill="url(#pattern0)"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use xlinkHref="#image0_1157_4905" transform="scale(0.015625)" />
            </pattern>
            <image
              id="image0_1157_4905"
              width="64"
              height="64"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQUlEQVR4nO2bO2gVQRSG10RNxAd2aewUNVgo6SOioHYKgRTGgII2IoRUimLpqxBFI4qKnUVsFEUIaCn4CIKPRrTQIqKVFsud/x98HDlkA8slyZ3Nvm/mhynuvXNmzvnu7JnZmd0gyEki0m2M2W6MGQJw3BgzrJ9FpGsem67IZjiyGTLG9GtbQV1krd0GYBxASFKaCwAD4J61ti9m06ff6W9z2ITaprV2a1BVicgKkncA/J0tiFmC0nrXSI4ltLmtfQVVUhiGPQAmXYLIogB4rX0GVZCIdJN8UVTwMQiTlRgJJMeKDj5WrpYd/CYAv8sCEPXdWyaAWyX++zPlZinBi0gHye8VAPBDfSkcAMlehyH6B8BZAHsAXNDPjjbnI5tzLjZ6KRYOAMCupEmK5A0Hm+tJkyyAnYUDIDng4NihuI219kArG60TtwFw2AHaQOEAjDH9DgAeNAEYdAAw2ATgYSsb9SX3gAFsiAIYIXkCwCXHJPWU5Cm1IXkmygVzFq0T1VWbZy59RL6ozYj6CGB9loHvBfAmRZaOOzru0N94Rn1NAti94MBFZAmAi1k4UwaAqL9/OpNoLIkBkDydZfBlAIiVk5VY4qIkAImXzMxpiVviCEi2ZGZOS9ySAXxzCl5EVuXkQNkARERWtgTQaDTWtSsAjc0DaKXGYh8BYRj2tCsAp41UEel0vAevFQBdC2hsgYuY0y5vySPguVPwKj2WajcAAI4FrhKRLgCf2gUAgI8isjxIImvtFgC/6g4AwM8Fb52T3AzgQ10BAHhHcmOQRiLSaYw5COCxrqfTzBB5A4h8Ux8f6dG6c9b38vLymkt6ugPgfhaF5KhDf6MZ9pf++Jzk57pOg+q7B5BW9COA/hKgzwH0SZB+FqCfBrkY1wGYvqeu5SUA4G1qACQnagzgSWoAAK7UGMDloh6Hk4oC2JEagIgsI/m1hgC+iMjSIAsZY4brBqD5ecNUEpEOTSh1AaAbuJk/Pywia9JukRcBAMB79TXT4GckIqt1y7nCACZEZG2Qp2T69Pio7r9XCICeBRwp9BxApt8S20/yLoBXJKf0GGq+onVbtRu116qdKZIvtS7JfZV4d8jLy8vLyyuonf4DlsW+DqK8zPEAAAAASUVORK5CYII="
            />
          </defs>
        </svg>
        <Typography
          variant="body2"
          className="my_activated_bonus__bonus-type-title"
        >
          {type === "cashback"
            ? "Кэшбек"
            : bonus_user_fs_type && bonus_user_pers_type
            ? "Эксклюзивный бонус"
            : bonus_user_fs_type
            ? "Фриспины"
            : "Бонус к депозиту"}
        </Typography>
      </Box>
      {bg_image && (
        <img
          className="my_activated_bonus__image__bg__image"
          src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}/bonuses/${bg_image}`}
          alt={bg_image}
        />
      )}
      {sub_image && (
        <img
          className="my_activated_bonus__image__sub__image"
          src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}/bonuses/${sub_image}`}
          alt={sub_image}
        />
      )}
      <Box className="my_activated_bonus__image__content">
        <Box className="my_activated_bonus__image__content__info">
          <Typography
            variant="h4"
            className="my_activated_bonus__image__content__title"
          >
            {display_name}
          </Typography>
          <Typography
            variant="h5"
            className="my_activated_bonus__image__content__sub-title"
          >
            {description}
          </Typography>
          <Link
            to={`/bonuses/${id}`}
            className="my_activated_bonus__image__content__link"
          >
            Условия бонуса
          </Link>
        </Box>
        {bonus_user_pers_type ? (
          bonus_user_pers_type.status === "active" ? (
            <button
              className="my_activated_bonus__image__content__button"
              onClick={() => {
                navagate("/profile/payments");
              }}
            >
              Пополнить
            </button>
          ) : (
            <Box sx={{ marginBottom: "48px" }}></Box>
          )
        ) : bonus_user_fs_type ? (
          bonus_user_fs_type.status === "active" ? (
            <button
              className="my_activated_bonus__image__content__button"
              onClick={() => {
                navagate("/profile/payments");
              }}
            >
              Пополнить
            </button>
          ) : (
            <Box sx={{ marginBottom: "48px" }}></Box>
          )
        ) : null}
      </Box>
    </Box>
  );
};
