import React from "react";
import { Box, Card, CardActions, CardContent, CardMedia } from "@mui/material";
import { IPaymentCardProps } from "../typing";
import "./PaymentCard.css";
import recomended from "../../../../assets/icons/recommended_icon.png";

export const PaymentCard = ({
  image,
  text,
  buttonText,
  id,
  active,
  recommended,
  onHover,
  onLeave,
  onClick,
}: IPaymentCardProps) => {
  return (
    <Card className="payment-card" onMouseOver={onHover} onMouseOut={onLeave}>
      <CardMedia
        className="payment-card__image"
        src={`https://enomo.pro/payments/${image}`}
        component={"img"}
        sx={{ height: 120, padding: 0 }}
      ></CardMedia>
      {recommended && (
        // <Box className="payment-card__recommended-badge">Рекомендовано</Box>
        <CardMedia
          className="payment-card__recommended-badge"
          image={recomended}
          component={"img"}
          sx={{ height: 120, padding: 0 }}
        ></CardMedia>
      )}
      {active === id && (
        <Box className="payment-card__active-container">
          <CardActions sx={{ padding: 0 }} className="payment-card__action">
            <button
              className="payment-card__action-button primary-hover"
              onClick={onClick}
            >
              {buttonText}
            </button>
          </CardActions>
          <CardContent sx={{ padding: 0 }} className="payment-card__label">
            {text}
          </CardContent>
        </Box>
      )}
    </Card>
  );
};
