import { createSelector } from "reselect";
import { RootState } from "../../store/rootStore";
import { IJivoSelector } from "./typing/jivo.interface";

const selectUserData = (state: RootState) => state.user;

export const jivoSelector = createSelector(selectUserData, (user) => {
  const loyaltyLevel = user?.loyalty?.level;
  const email = user.contacts.email;
  const phone = user.contacts.phone;
  const id = user.id;

  return {
    loyaltyLevel,
    email,
    phone,
    id,
  } as IJivoSelector;
});
