import { createAsyncThunk } from "@reduxjs/toolkit";
import { BonusesService } from "../../../services/bonuses/bonuses.service";
import { setBrandLoyalty } from "./loyalty.slice";
import { LoyanltyAction } from "./typing/loyalty.enum";

export const getBrandLoyalty = createAsyncThunk(
  LoyanltyAction.GET_BRAND_LOYALTY,
  async (_, { getState, dispatch }) => {
    try {
      const bonusService = new BonusesService();
      const { result, error } = await bonusService.getLoyalty();
      if (error && error.response.status === 400) {
      }
      if (result) {
        dispatch(setBrandLoyalty(result));
      }
    } catch (error) {}
  }
);

export const updateLoyalty = createAsyncThunk(
  LoyanltyAction.UPDATE_LOYALTY,
  async (_: { user_id: string }, { getState, dispatch }) => {
    const { user_id } = _;
    try {
      const bonusService = new BonusesService();
      const { result, error } = await bonusService.updateLoyalty(user_id);
      if (error && error.response.status === 400) {
      }
      if (result) {
        dispatch(setBrandLoyalty(result));
      }
    } catch (error) {}
  }
);
