import { Container } from "@mui/material";
import React, { useEffect } from "react";

type IGameFrameProps = {
  game_url: string;
  is_demo: boolean;
  user_id: string;
  currency: string;
};

export const GameFrame = ({
  game_url,
  is_demo,
  user_id,
  currency,
}: IGameFrameProps) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      //console.log("Event: beforeunload registered");
      if (!is_demo && user_id) {
        const data = {
          event: "page_unload",
          user_id,
        };
        const headers = {
          type: "application/json",
        };
        const blob = new Blob([JSON.stringify(data)], headers);
        navigator.sendBeacon(
          `${process.env.REACT_APP_SERVER_URL}/api/payments/loyalty-update`,
          blob
        );
      }
    };

    const handleVisibilityChange = () => {
      console.log("Event: visibilitychange registered");
      if (document.visibilityState === "hidden" && !is_demo && user_id) {
        const data = {
          event: "page_hidden",
          user_id,
        };
        const headers = {
          type: "application/json",
        };
        const blob = new Blob([JSON.stringify(data)], headers);
        navigator.sendBeacon(
          `${process.env.REACT_APP_SERVER_URL}/api/payments/loyalty-update`,
          blob
        );
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    //console.log("Event listeners added");

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      //console.log("Event listeners removed");
    };
  }, [is_demo, user_id, window, document]);

  return (
    <Container maxWidth={false} disableGutters>
      <iframe
        title={game_url}
        src={
          is_demo
            ? `https://int.apiforb2b.com/games/${game_url}?operator_id=0&currency=&user_id=0&auth_token=`
            : `https://int.apiforb2b.com/games/${game_url}?operator_id=${
                process.env.REACT_APP_B2B_OPERATOR_ID
              }&user_id=${user_id}&auth_token=${localStorage.getItem(
                "game-token"
              )}&currency=${currency}&language=RU`
        }
        style={{
          height: `calc(100vh - 100px)`,
          width: "100%",
          border: "none",
        }}
      />
    </Container>
  );
};
