import { Box } from "@mui/material";
import "./active.bonus.item.css";
import { IActiveBonus } from "../../../store/models/bonuses/typing/bonuses.interface";
import { ActiveBonusImage } from "../ActiveBonusImage/active.bonus.image";
import { ActiveBonusInfo } from "../ActiveBonusInfo/active.bonus.info";

interface IActiveBonusItemProps extends IActiveBonus {
  customClassName: string;
  bg_img: string;
  sub_img: string;
  cancelBonus?: (bonus_id: number) => void;
}
export const ActiveBonusItem = ({
  id,
  display_name,
  description,
  bonus,
  bonus_user_fs_type,
  bonus_user_pers_type,
  bg_img,
  sub_img,
  start_date,
  end_date,
  customClassName,
  cancelBonus,
}: IActiveBonusItemProps) => {
  const daysLeft = Math.floor(
    (+bonus.end_date - Date.now()) / (1000 * 60 * 60 * 24)
  );

  return (
    <Box
      className={`my_activated_bonus__container ${
        customClassName && customClassName
      } `}
    >
      <ActiveBonusImage
        id={bonus.id}
        type={bonus.type}
        bonus_user_fs_type={bonus_user_fs_type}
        bonus_user_pers_type={bonus_user_pers_type}
        bg_image={bg_img}
        sub_image={sub_img}
        display_name={display_name}
        description={description}
      />
      <ActiveBonusInfo
        id={bonus.id}
        type={bonus.type}
        bonus_user_fs_type={bonus_user_fs_type}
        bonus_user_pers_type={bonus_user_pers_type}
        days_left={daysLeft}
        min_dep={bonus.min_dep}
        game={bonus.fs?.game}
        cancelBonus={cancelBonus}
      />
    </Box>
  );
};
