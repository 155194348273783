import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import { Footer } from "../../components/Footer/Footer";
import { GameHeader } from "../../components/Game/GameHeader/GameHeader";
import { Loader } from "../../components/loader/loader";
import { verifyAuthUser } from "../../store/models/auth/auth.thunk";
import { GameSelector } from "./game.selector";
import { GameFrame } from "../../components/Game/Frame/frame";
import { CloseModal } from "../../components/Game/CloseModal/close.modal";
import "./game.css";
import { GameTypeProvider } from "../../components/Game/GameTypeProvider/game.type.provider";
import { fetchMyActivatedBonuses } from "../../store/models/bonuses/bonuses.thunk";
import { updateLoyalty } from "../../store/models/loyalty/loyalty.thunk";

export const Game = ({
  setHidden,
}: {
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [exit, setExit] = useState(false);
  const [exitType, setExitType] = useState("");
  const game_url = location.pathname.split("/")[2];
  const { userId, currency, isDemo, activeBonus, isAuth } =
    useAppSelector(GameSelector);
  const [chooseGameType, setChooseGameType] = useState<
    "real" | "bonus" | undefined
  >(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backHandler = () => {
    setExit(true);
    setExitType("exit");
  };
  const depositHandler = () => {
    setExit(true);
    setExitType("deposit");
    // navigate("/profile/payments/");
  };
  const registrationHandler = () => {
    setExit(true);
    setExitType("exit");
    navigate("/registration");
  };
  const handleCloseDialog = () => {
    setExit(false);
  };
  const handleGoBack = () => {
    dispatch(updateLoyalty({ user_id: userId }));
    dispatch(verifyAuthUser());

    if (exitType === "deposit") {
      navigate("/profile/payments/");
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    dispatch(fetchMyActivatedBonuses({}));
    setHidden(true);
    window.scrollTo(0, 0);
    return () => {
      setHidden(false);
    };
  }, []);

  if (!currency) {
    return <Loader />;
  }
  return (
    <>
      <Box className="game">
        <GameHeader
          demo={isDemo}
          auth={isAuth}
          backHandler={backHandler}
          depositHandler={depositHandler}
          registrationHandler={registrationHandler}
        />
        <GameTypeProvider
          activeBonus={
            activeBonus.filter(
              (bonus) =>
                bonus.bonus_user_fs_type?.status === "deposit" ||
                bonus.bonus_user_fs_type?.status === "wagering" ||
                bonus.bonus_user_fs_type?.status === "use" ||
                bonus.bonus_user_pers_type?.status === "deposit" ||
                bonus.bonus_user_pers_type?.status === "wagering" ||
                bonus.bonus_user_pers_type?.status === "use"
            ).length > 0
          }
          chooseGameType={chooseGameType}
          setChooseGameType={setChooseGameType}
          gameUrl={game_url}
          isDemo={isDemo}
          userId={userId}
          currency={currency}
        />
      </Box>
      <CloseModal open={exit} close={handleCloseDialog} back={handleGoBack} />
      <Footer />
    </>
  );
};
